<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],

      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      admininfo: {
        username: "",
        phone: "",
        country: 86,
      },
      wristbandsetting: {},
      userpassword: "",
      reinputuserpassword: "",
      loading: true,
      adminid: 0,
      showsuccess: false,
      countrylist: [],
      systemtype: 0,
      card: "",
      type: true,
      wristbandconfig: [],
      cashiersetting: true,
      tablesdata: [],
      cashierconfig: [],
      cashiertable_hll: [],
      cashierstatus: false,
      devicelist: [],
    };
  },
  mounted() {
    var that = this;
    that.title = that.$t("menuitems.user.add");
    that.items = [
      {
        text: that.$t("menuitems.user.text"),
        href: "/",
      },
      {
        text: that.$t("menuitems.user.add"),
        active: true,
      },
    ];

    that.userinfo = JSON.parse(localStorage.getItem("user"));
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      // that.ismobile = true;
    }
    that.countrylist = [
      {
        value: 86,
        label: that.$t("global.country.china"),
      },
      {
        value: 1,
        label: that.$t("global.country.usa"),
      },
      {
        value: 81,
        label: that.$t("global.country.japan"),
      },
      {
        value: 44,
        label: that.$t("global.country.uk"),
      },
      {
        value: 34,
        label: that.$t("global.country.spain"),
      },
      {
        value: 33,
        label: that.$t("global.country.french"),
      },
      {
        value: 60,
        label: that.$t("global.country.malaysia"),
      },
      {
        value: 65,
        label: that.$t("global.country.singapore"),
      },
    ];
    that.getsysteminfo();
    that.getcashierconfig();
    that.card = that.$route.query.id;
    var numReg = /^[0-9]*$/;
    var numRe = new RegExp(numReg);
    if (!numRe.test(that.card) || that.card.length == 8) {
      that.type = false;
      that.loading = false;
      that.getwristbandsetting();
    } else {
      that.adminid = that.$route.query.id;
      that.getgadmindetail();
    }
    that.getdevicelist();
  },
  methods: {
    getsysteminfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "systeminfo",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.systemtype = parseInt(response.data.data.model);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getgadmindetail() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getuserinfo",
            id: that.adminid,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          let newdata = response.data.data;
          that.admininfo = {
            username: newdata.username,
            phone: newdata.phone,
            country: newdata.country,
          };
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    addadminuser() {
      var that = this;
      if (!that.admininfo.username) {
        that.$message.error(that.$t("admin.add.usernotintut"));
        return;
      }
      if (that.admininfo.username < 4) {
        that.$message.error(that.$t("admin.add.userlong"));
        return;
      }
      if (!that.admininfo.phone) {
        that.$message.error(that.$t("admin.add.phonenotintut"));
        return;
      }
      if (that.admininfo.phone < 11) {
        that.$message.error(that.$t("admin.add.phonelong"));
        return;
      }
      let postdata = {
        action: "addnewuser",
        id: that.adminid,
        phone: that.admininfo.phone,
        country: that.admininfo.country,
        username: that.admininfo.username,
        password: that.reinputuserpassword,
        hex: that.card,
      };
      // console.log(that.card);
      that.$axios
        .post(that.apiuri, postdata, {
          headers: { Openid: that.userinfo.openid },
        })
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.showsuccess = true;
          } else {
            if (response.data.msg) {
              that.$message.error(
                that.$t("user.add.error." + response.data.msg)
              );
            } else {
              that.$message.error(that.$t("global.delete.500"));
            }
          }
        })
        .catch(function (error) {
          $t("user.add.error.havenopayorder");
          console.log(error);
        });
    },
    addwhitelist() {
      var that = this;
      if (!that.admininfo.username) {
        that.$message.error(that.$t("admin.add.usernotintut"));
        return;
      }
      let postdata = {};
      if (that.admininfo.phone) {
        postdata = {
          action: "addwhitelist",
          id: that.card,
          user: that.admininfo.username,
          phone: that.admininfo.phone,
          wristband: that.wristbandsetting,
        };
      } else {
        postdata = {
          action: "addwhitelist",
          id: that.card,
          user: that.admininfo.username,
          wristband: that.wristbandsetting,
        };
      }
      that.$axios
        .post(that.apiuri, postdata, {
          headers: { Openid: that.userinfo.openid },
        })
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.showsuccess = true;
          } else {
            if (response.data.msg) {
              that.$message.error(
                that.$t("admin.add.error." + response.data.msg)
              );
            } else {
              that.$message.error(that.$t("global.delete.500"));
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    checkinputphone(val) {
      console.log(val);
    },
    getwristbandsetting() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getwristbandsetting",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.wristbandconfig = response.data.config.maxprice
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getcashierconfig() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "get_cashier_config",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          if (response.data.status == 200) {
            that.cashierconfig = response.data.config
            that.cashierstatus = that.cashierconfig.status
            if (that.cashierconfig.type == "hualala") {
              that.cashiersetting = false
            }
          }

        })
        .catch(function (error) {
          console.log(error);
        });
    },
    gettablesdata() {
      var that = this;
      that.loading = true
      that.$axios
        .post(
          that.apiuri,
          {
            action: "get_" + that.cashierconfig.type + "_tables",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false
          that.cashiertable_hll = response.data.msgQueueDataList;
          if (that.cashiertable_hll.length == 0) {
            that.$message.error("没有获取到桌台信息")
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getdevicelist() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getdevicelist",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.devicelist = response.data.data;
          console.log(that.devicelist);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading" v-if="!showsuccess">
            <form class="form-group" id="adminaddform">
              <div class="form-group" v-if="cashierstatus || cashierconfig.type == 'hualala'">
                <label for="username" style="display: block">{{
                  $t("admin.add.username")
                }}</label>
                <input type="text" class="form-control" id="username" name="username" v-model="admininfo.username"
                  style="width: 80%; float: left" :placeholder="$t('admin.add.username')"
                  v-if="cashiertable_hll.length == 0" />
                <el-select v-model="admininfo.username" placeholder="请选择" v-else>
                  <el-option v-for="item in cashiertable_hll" :key="item.saasOrderKey" :label="item.tableName"
                    :value="item.tableName + '|' + item.saasOrderKey">
                  </el-option>
                </el-select>
                <el-button type="primary" plain @click="gettablesdata">选择收银系统桌号</el-button>
              </div>
              <div class="form-group" v-else>
                <label for="username">{{ $t("admin.add.username") }}</label>
                <input type="text" class="form-control" id="username" name="username" v-model="admininfo.username"
                  :placeholder="$t('admin.add.username')" />
              </div>

              <div class="form-group">
                <label for="phone">{{ $t("admin.add.phone") }}</label>
                <el-row>
                  <el-col :span="6">
                    <div class="grid-content bg-purple">
                      <el-select v-model="admininfo.country" :placeholder="$t('admin.add.selectcountry')" filterable>
                        <el-option v-for="item in countrylist" :key="item.value" :label="item.label"
                          :value="item.value">
                          <span style="float: left">{{ item.label }}</span>
                          <span>{{ item.value }}</span>
                        </el-option>
                      </el-select>
                    </div>
                  </el-col>
                  <el-col :span="18">
                    <div class="grid-content bg-purple-light">
                      <el-input :placeholder="$t('admin.add.phone')" v-model="admininfo.phone" maxlength="11">
                      </el-input>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="form-group" v-if="wristbandconfig.active && wristbandconfig.usermode">
                <label for="actives">{{
                  $t("marketing.wristband.maxprice")
                }}</label>
                <el-switch v-model="wristbandsetting.active"></el-switch>
              </div>
              <div class="form-group" v-if="wristbandconfig.active &&
                wristbandconfig.usermode &&
                wristbandsetting.active && !wristbandconfig.systemtype
              ">
                <label for="time">{{ $t("marketing.wristband.time") }}</label>
                <el-time-picker is-range v-model="wristbandsetting.time"
                  :range-separator="$t('marketing.wristband.timeto')"
                  :start-placeholder="$t('marketing.wristband.starttime')"
                  :end-placeholder="$t('marketing.wristband.enttime')" :placeholder="$t('marketing.wristband.select')"
                  format="HH:mm:ss">
                </el-time-picker>
              </div>
              <div class="form-group" v-if="wristbandconfig.active &&
                wristbandconfig.usermode &&
                wristbandsetting.active && wristbandconfig.systemtype
              ">
                <label for="price">{{ $t("marketing.wristband.device") }}</label>
                <el-select v-model="wristbandsetting.device" multiple filterable :placeholder="$t('global.text.select')">
                  <el-option v-for="item in devicelist" :key="item.ED_id" :label="item.name" :value="item.ED_id">
                  </el-option>
                </el-select>
              </div>
              <div class="form-group" v-if="wristbandconfig.active &&
                wristbandconfig.usermode &&
                wristbandsetting.active && wristbandconfig.systemtype
              ">
                <label for="price">{{ $t("marketing.wristband.pricesingle") }}</label>
                <el-input v-model="wristbandsetting.price" :placeholder="$t('marketing.wristband.pricesingle')"></el-input>
              </div>
              <div class="form-group" v-if="wristbandconfig.active &&
                wristbandconfig.usermode &&
                wristbandsetting.active && wristbandconfig.systemtype
              ">
                <label for="price">{{ $t("marketing.wristband.maxpricevalue") }}</label>
                <el-input v-model="wristbandsetting.maxprice" :placeholder="$t('marketing.wristband.maxpricevalue')"></el-input>
              </div>

              <el-row :gutter="20">
                <el-col :span="6">
                  <div class="grid-content bg-purple">
                    <a v-if="card && systemtype === 2 && !type" href="javascript:;" class="btn btn-info btn-fw"
                      @click="addwhitelist">{{ $t("user.add.temp") }}</a>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="grid-content bg-purple text-center">
                    <a v-if="cashiertable_hll.length == 0" href="javascript:;" class="btn btn-success btn-fw"
                      @click="addadminuser">{{
                        $t("goods.additem.save") }}</a>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content bg-purple text-right">
                    <router-link :to="{
                      path: '/user/list',
                    }">
                      <a href="javascript:;" class="btn btn-light btn-fw">{{
                        $t("global.return")
                      }}</a>
                    </router-link>
                  </div>
                </el-col>
              </el-row>
            </form>
          </div>
          <div v-else>
            <el-result icon="success" :title="$t('global.success')">
              <template slot="extra">
                <router-link :to="{
                  path: '/user/list',
                }">
                  <el-button type="primary" size="medium">{{
                    $t("global.return")
                  }}</el-button>
                </router-link>
              </template>
            </el-result>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>